import {
  Anchor,
  Container,
  Divider,
  Flex,
  Stack,
  Text,
  ThemeIcon,
  Box,
} from "@mantine/core";
import dayjs from "dayjs";
import Link from "next/link";
import React from "react";
import { FaDiscord, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { JSONCrackLogo } from "./NervalTextLogo";

export const Footer = () => {
  return (
    <Container mt={200}>
      <Divider my={60} color="gray.3" />
      <Flex justify="space-between" align="flex-start">
        <Stack style={{ flex: 1 }}>
          <JSONCrackLogo />
          <Anchor href="mailto:contact@nerval.org" fz="sm" c="dimmed">
            contact@nerval.org
          </Anchor>
        </Stack>
        <Flex gap={60} style={{ flex: 2 }}>
          <Stack gap="xs">
            <Text fz="sm" c="dimmed">
              Product
            </Text>
            <Anchor component={Link} prefetch={false} fz="sm" c="gray.5" href="/pricing">
              Pricing
            </Anchor>
          </Stack>
          <Stack gap="xs">
            <Text fz="sm" c="dimmed">
              Resources
            </Text>
            <Anchor fz="sm" c="gray.5" href="https://rishav.cc/" target="_blank">
              Affiliate
            </Anchor>
            <Anchor component={Link} prefetch={false} fz="sm" c="gray.5" href="/#faq">
              FAQ
            </Anchor>
            <Anchor component={Link} prefetch={false} fz="sm" c="gray.5" href="/docs">
              Docs
            </Anchor>
          </Stack>
          <Stack gap="xs">
            <Text fz="sm" c="dimmed">
              Social
            </Text>
            <Flex gap="md">
              {[
                { label: "LinkedIn", icon: FaLinkedin, href: "https://www.linkedin.com/company/nervaLML" },
                { label: "X", icon: FaXTwitter, href: "https://twitter.com/nervalML" },
                { label: "Discord", icon: FaDiscord, href: "https://discord.gg/j2qCwyuvzV" },
              ].map(({ label, icon: Icon, href }) => (
                <Anchor
                  key={label}
                  aria-label={label}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ThemeIcon
                    variant="light"
                    color="gray.5"
                    size="lg"
                    style={{
                      transition: "all 0.2s ease",
                      '&:hover': {
                        backgroundColor: 'gray.7',
                        color: 'white',
                      },
                    }}
                  >
                    <Icon size={24} />
                  </ThemeIcon>
                </Anchor>
              ))}
            </Flex>
          </Stack>
        </Flex>
      </Flex>
      <Box mt={60}>
        <Flex justify="center" align="center" wrap="wrap" gap="md">
          <Text fz="sm" c="dimmed">
            © {dayjs().get("year")} Nerval ML
          </Text>
          <Anchor component={Link} prefetch={false} fz="sm" c="dimmed" href="/legal/terms">
            Terms
          </Anchor>
          <Anchor component={Link} prefetch={false} fz="sm" c="dimmed" href="/legal/privacy">
            Privacy
          </Anchor>
        </Flex>
      </Box>
    </Container>
  );
};

export default Footer;