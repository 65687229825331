import { Button, Container, Group, Flex, ButtonProps } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import styled from "styled-components";
import Link from "next/link";
import React from "react";
import { Logo } from "src/containers/Toolbar/Logo";
import { MdDashboard, MdPlayArrow } from "react-icons/md";
import useUser from "src/store/useUser";

const StyledNavbarWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(20, 20, 20, 0.8); /* Darker charcoal semi-transparent background */
  backdrop-filter: blur(10px); /* Frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional border for better visibility */
`;

const StyledNavbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    display: flex;
    align-items: center;
    height: 100%;
  }

  img {
    height: 28px; /* 3/5 of 50px navbar height */
    width: auto;
  }
`;

const NavbarSpacer = styled.div`
  height: 50px; // Reduce this to match the navbar height
`;

interface StyledNavButtonProps extends ButtonProps {
  $selected?: boolean;
  children: React.ReactNode;
  component?: string;
}

const StyledNavButton = styled(Button)<StyledNavButtonProps>`
  ${({ $selected }) =>
    $selected &&
    `
    background-color: rgba(0, 53, 108, 0.2); /* Updated to match the new Dashboard button color */
    color: #eeeeee; /* Keep Home text color as #eeeeee */
  `}
`;

interface StyledLoginButtonProps extends ButtonProps {
  children: React.ReactNode;
  component?: string;
}

const StyledLoginButton = styled(Button)<StyledLoginButtonProps>`
  border: 1px solid rgba(238, 238, 238, 0.5); // Light gray border with 50% opacity
  transition: border-color 0.3s ease;
  background-color: transparent !important; // Ensure transparent background
  height: auto; // Allow height to adjust based on content
  display: flex;
  align-items: center;

  &:hover {
    border-color: #625BF6; /* Brighter blue color for hover state */
  }

  /* Ensure the text color is #eeeeee */
  color: #eeeeee !important;
`;

export const Navbar = () => {
  const isMobile = useMediaQuery("(max-width: 62em)");
  const isAuthenticated = useUser((state) => state.isAuthenticated);

  return (
    <>
      <StyledNavbarWrapper>
        <Container size="xl">
          <StyledNavbar>
            <StyledLogo>
              <Link href="/" passHref legacyBehavior>
                <a>
                  <Logo />
                </a>
              </Link>
            </StyledLogo>
            {!isMobile && (
              <Flex align="center" justify="center" style={{ flex: 1 }}>
                <Group gap="md">
                  <Link href="/" passHref legacyBehavior>
                    <StyledNavButton
                      component="a"
                      variant="subtle"
                      c="gray"
                      radius="md"
                      size="sm"
                      $selected
                    >
                      Home
                    </StyledNavButton>
                  </Link>
                  <Link href="/learn" passHref legacyBehavior>
                    <StyledNavButton
                      component="a"
                      variant="subtle"
                      c="gray"
                      radius="md"
                      size="sm"
                    >
                      Learn
                    </StyledNavButton>
                  </Link>
                  <Link href="/features" passHref legacyBehavior>
                    <StyledNavButton
                      component="a"
                      variant="subtle"
                      c="gray"
                      radius="md"
                      size="sm"
                    >
                      Features
                    </StyledNavButton>
                  </Link>
                  <Link href="/guides" passHref legacyBehavior>
                    <StyledNavButton
                      component="a"
                      variant="subtle"
                      c="gray"
                      radius="md"
                      size="sm"
                    >
                      Guides
                    </StyledNavButton>
                  </Link>
                  <Link href="/support" passHref legacyBehavior>
                    <StyledNavButton
                      component="a"
                      variant="subtle"
                      c="gray"
                      radius="md"
                      size="sm"
                    >
                      Support
                    </StyledNavButton>
                  </Link>
                </Group>
              </Flex>
            )}
            <Group gap="xs">
              {!isAuthenticated && (
                <Link href="/sign-in" passHref legacyBehavior>
                  <StyledLoginButton
                    component="a"
                    c="blue"
                    radius="md"
                    size="sm"
                    styles={{
                      root: {
                        color: "#eeeeee !important",
                        padding: "6px 12px",
                        fontSize: "14px",
                        height: "32px",
                        lineHeight: "20px",
                      }
                    }}
                  >
                    Login
                  </StyledLoginButton>
                </Link>
              )}
              <Link href={isAuthenticated ? "/dashboard" : "https://nerval.org/sign-up"} passHref legacyBehavior>
                <Button
                  component="a"
                  variant="filled"
                  color="blue"
                  style={{
                    border: "1px solid #625BF6",
                    color: "#eeeeee",
                    padding: "6px 12px",
                    fontSize: "14px",
                    height: "32px",
                    lineHeight: "20px",
                  }}
                  radius="md"
                  size="sm"
                  rightSection={isAuthenticated ? <MdDashboard size={16} /> : <MdPlayArrow size={16} />}
                >
                  {isAuthenticated ? "Go to Dashboard" : "Get Started"}
                </Button>
              </Link>
            </Group>
          </StyledNavbar>
        </Container>
      </StyledNavbarWrapper>
      <NavbarSpacer />
    </>
  );
};