import React from "react";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";

const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="pb-12">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
